module.exports ={
  "propertyService": "https://ded2nt9g27.execute-api.us-east-1.amazonaws.com/test",
  "domusoApiService": "https://3lkjv56cu0.execute-api.us-east-1.amazonaws.com/test",
  "appService": "https://app.devmuso.com",
  "reservationService": "https://2cvqdkhz4g.execute-api.us-east-1.amazonaws.com/test",
  "userPoolId": "us-east-1_tuVPUHTxf",
  "authService": "https://m3l6bbcl3h.execute-api.us-east-1.amazonaws.com/test",
  "userPoolWebClientId": "7j131uo6qmknsnblj9pr1o04it",
  "emailLinkBase": "https://app.devmuso.com",
  "payWithUrl": "https://paywith.devmuso.com",
  "supportEmail": "support-test@devmuso.com",
  "residentWebUI": "https://resident.devmuso.com",
  "maintenanceUrl": "https://m3l6bbcl3h.execute-api.us-east-1.amazonaws.com/test/maintenance",
  "env": "test"
}